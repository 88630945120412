import { environmentSelector } from './../../../environments/environment.selector';
import { VisitService } from './../visit.service';
import { FiltrosService } from './../../application/shared/filtros.service';
import { LenderService } from './../../lender/lender.service';
import { ApplicationService } from './../../application/shared/application.service';
import { SessionData } from './../../shared/interfaces/session-data';
import { filter, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { InformacionRecopiladaFilter } from './informacion-recopilada.model';
import moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { saveAs } from 'file-saver';
import { LoadingService } from '../../shared/components/loading-bar/loading-bar.service';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';

declare var $: any;



@Component({
    selector: 'app-informacion-recompilada',
    templateUrl: './informacion-recopilada.component.html',
    styleUrls: ['./informacion-recopilada.component.css'],
    providers: [LenderService, VisitService]
})
export class InformacionRecopiladaComponent {
    private environment = environmentSelector();

    filtrosCorreosAbiertos = false;
    filtrosTelefonosAbiertos = false;
    listFiltered = false;
    public sessionData: SessionData;
    public cargandoCorreosElectronicos = false;
    public lenders: any[] = [];
    public idLender: any;
    ayudaCorreos = false;
    ayudaTelefonos = false;
    public filters: InformacionRecopiladaFilter;
    public pagination: IPaginatorCorreosElectronicos;
    public paginationTelefonos: IPaginatorCorreosElectronicos;
    public primeraVezTelefonos = true;
    hijosActivos: boolean = false;
    today = new Date();
    public contabilizadorFiltros: FiltersContabilizer;
    public totalFiltrosCorreos: number = 0;
    public totalFiltrosTelefonos: number = 0;

    @ViewChild('filtrosSideNavTelefonos') filtrosSideNavTelefonos!: MatSidenav;
    @ViewChild('filtrosSideNavCorreos') filtrosSideNavCorreos!: MatSidenav;
    queryParamsGlobal: string;
    queryParamsGlobalCorreo: string;
    momentVar: any;
    public loadings: any = {
        correos: false,
        financieras: false,
        telefonos: false
    }
    public correosElectronicos = [];
    public datosConsultaCorreos: any = {
        slt: '"idExternoCliente", nombre,  "idCliente",nombreAcreditado, "nombreAgente", "correo", fecha, apePatAcreditado, apeMatAcreditado, apePatAgente, apeMatAgente, "subIdExterno","folioDomicilio","idFinanciera", "idCobrador"',
        pageSize: 50,
        rows: 50,
        orderBy: 'fecha DESC',
        query: "",
        fromRowPage: 0,
        toRowPage: 10,
        sortField: 'fecha',
        sortAsc: ''
    }
    public datosConsultaTelefonos: any = {
        slt: '"idExternoCliente",nombre,  "idCliente",nombreAcreditado, "nombreAgente", "numeroTelefono", fecha, apePatAcreditado, apeMatAcreditado, apePatAgente, apeMatAgente, "subIdExterno","folioDomicilio","idFinanciera", "idCobrador"',
        pageSize: 50,
        rows: 50,
        orderBy: 'fecha DESC',
        query: "",
        fromRowPage: 0,
        toRowPage: 10,
        sortField: 'fecha',
        sortAsc: ''
    }

    public telefonos = [];
    public cargandoTelefonos = false;
    public tablaCorreosElectronicos: any;
    public tablaTelefonos: any;
    public subscribeRouterParamsBounded: Function;

    constructor(
        private lenderService: LenderService,
        private localStorageService: LocalStorageService,
        private rootElement: ElementRef,
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        private visitService: VisitService,
        private formBuilder: FormBuilder,
        private routeNavigate: Router,
        private http: HttpClient,
        private loadingService: LoadingService
    ) {
        this.sessionData = this.localStorageService.getSessionData();
        if(!this.sessionData.isSuperAdmin){
            this.idLender = this.sessionData.idFinanciera;
        }
        this.filters = new InformacionRecopiladaFilter();
        this.telefonos = [];
        this.correosElectronicos = [];
        this.momentVar = moment;
        this.crearTablas();
        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.suscribeParams.bind(this));
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();

    };

    private agregarContabilizadoresDeFiltros(){
        this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
            'financiera'
        ]);

        this.contabilizadorFiltros.add('Generales', 'generalesC', [
            'idAcreditado'
        ]);
        this.contabilizadorFiltros.add('Fechas', 'fechasC', [
            'fechaInicio',
            'fechaFin'
        ]);

        this.contabilizadorFiltros.add('Generales', 'generalesT', [
            'idAcreditadoTelefonos'
        ]);

        this.contabilizadorFiltros.add('Fechas', 'fechasT', [
            'fechaInicioTelefonos',
            'fechaFinTelefonos'
        ]);

    }

    public contabilizadorDeFiltrosTotalesCorres():void{

        let totalFiltrosArrayCorreos: number[] = [];

        if(this.sessionData.isSuperAdmin){
            totalFiltrosArrayCorreos.push( this.contabilizadorDeFiltros('superAdmin') );
        }
        totalFiltrosArrayCorreos.push( this.contabilizadorDeFiltros('generalesC') );
        totalFiltrosArrayCorreos.push( this.contabilizadorDeFiltros('fechasC') );

        this.totalFiltrosCorreos = totalFiltrosArrayCorreos.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    public contabilizadorDeFiltrosTotalesTelefonos():void{

        let totalFiltrosArrayTelefonos: number[] = [];

        if(this.sessionData.isSuperAdmin){
            totalFiltrosArrayTelefonos.push( this.contabilizadorDeFiltros('superAdmin') );
        }
        totalFiltrosArrayTelefonos.push( this.contabilizadorDeFiltros('generalesT') );
        totalFiltrosArrayTelefonos.push( this.contabilizadorDeFiltros('fechasT') );

        this.totalFiltrosTelefonos = totalFiltrosArrayTelefonos.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    public contabilizadorDeFiltros(filtroName: string){
      return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
    }

    crearTablas(){
        this.tablaCorreosElectronicos= {
            headers: false,
            tableHeaders: false,
            multiSelect: false,
            columnas: [
                {
                    name: 'Cliente',
                    key: 'nombre',
                    hide: false
                },{
                    name: 'ID Cuenta',
                    key: 'idExternoCliente',
                    hide: false,
                    type: 'link'
                },  {
                    name: 'ID Subcuenta',
                    key: 'subIdExterno',
                    hide: false
                },{
                    name: 'Folio Domicilio',
                    key: 'folioDomicilio',
                    hide: false
                },  {
                    name: 'Cuenta',
                    key: 'nombreAcreditadoCompleto',
                    hide: false
                }, {
                    name: 'Agente',
                    key: 'nombreAgenteCompleto',
                    hide: false,
                    type: 'link'
                }, {
                    name: 'Email',
                    key: 'correo',
                    hide: false
                }, {
                    name: 'Fecha',
                    key: 'fecha',
                    hide: false
                }
            ]
        }


        this.tablaTelefonos = {
            headers: false,
            tableHeaders: false,
            multiSelect: false,
            columnas: [
                {
                    name: 'Cliente',
                    key: 'nombre',
                    hide: false
                },{
                    name: 'ID Cuenta',
                    key: 'idExternoCliente',
                    hide: false,
                    type: 'link'
                }, {
                    name: 'ID Subcuenta',
                    key: 'subIdExterno',
                    hide: false
                },{
                    name: 'Folio Domicilio',
                    key: 'folioDomicilio',
                    hide: false
                }, {
                    name: 'Cuenta',
                    key: 'nombreAcreditadoCompleto',
                    hide: false
                }, {
                    name: 'Agente',
                    key: 'nombreAgenteCompleto',
                    hide: false,
                    type: 'link'
                }, {
                    name: 'Teléfono',
                    key: 'numeroTelefono',
                    hide: false
                }, {
                    name: 'Fecha',
                    key: 'fecha',
                    hide: false
                }
            ]
        }
    }

    suscribeParams(filtrosNuevos: any) {

        if (filtrosNuevos){
            this.filters = filtrosNuevos;
            this.filters.filter = true;

            if(this.filters.financiera){
                this.idLender = this.filters.financiera.idFinanciera;
            }
        }

        if(!this.sessionData.isSuperAdmin){
            this.idLender = this.sessionData.idFinanciera;
        }

        this.loadLenders();

    }

    public loadLenders(): void {
        this.loadings.financieras = true;

        this.lenderService.getAll('all').subscribe(
            res => {
                this.lenders = res;
                this.onChangeLender(this.idLender);
                if(this.filters.filter){
                    this.obtenerCorreosElectronicos(this.filters.page || 1);
                    this.obtenerTelefonos(this.filters.pageTelefonos || 1);

                }

            },
            err => {
                this.app.showError(err);
                this.loadings.financieras = false;
            },
            () => this.loadings.financieras = false
        );
        this.contabilizadorDeFiltros('superAdmin');
    }

    activarHijo(){
        this.hijosActivos = true;
    }

    actualizarCorreos(){
        this.obtenerCorreosElectronicos(this.filters.page || 1);
    }
    actualizarTelefonos(){
        this.obtenerTelefonos(this.filters.pageTelefonos || 1);
    }

    obtenerCorreosElectronicos(page: number) {
        const queryParams = this.obtenerQueryParamCorreos(page);
        this.loadings.correos = true;
        this.visitService.obtenerCorreosElectronicos(queryParams).pipe(take(1)).subscribe((res: any) => {
            this.pagination = res.data;


            for (let i = 0; i < res.data.pageData.length; i++) {
                res.data.pageData[i].nombreAcreditadoCompleto = `${res.data.pageData[i].nombreAcreditado} ${res.data.pageData[i].apePatAcreditado ? res.data.pageData[i].apePatAcreditado : ''} ${res.data.pageData[i].apeMatAcreditado ? res.data.pageData[i].apeMatAcreditado : ''}`;
                res.data.pageData[i].nombreAgenteCompleto = `${res.data.pageData[i].idCobrador} - ${res.data.pageData[i].nombreAgente} ${res.data.pageData[i].apePatAgente ? res.data.pageData[i].apePatAgente : ''} ${res.data.pageData[i].apeMatAgente ? res.data.pageData[i].apeMatAgente : ''}`;
                res.data.pageData[i].fecha = this.momentVar(new Date(res.data.pageData[i].fecha)).format('DD/MM/YYYY HH:mm');
            }

            this.correosElectronicos = res.data.pageData;
            this.contabilizadorDeFiltrosTotalesCorres();
            this.loadings.correos = false;
        }, (error: any) => {
            this.app.showError(error);

            this.loadings.correos = false;
        });
    }


    obtenerTelefonos(page: number) {
        const queryParams = this.obtenerQueryParamTelefonos(page);
        this.loadings.telefonos = true;
        this.visitService.obtenerTelefonos(queryParams).pipe(take(1)).subscribe((res: any) => {
            this.paginationTelefonos = res.data;

            for (let i = 0; i < res.data.pageData.length; i++) {
                res.data.pageData[i].nombreAcreditadoCompleto = `${res.data.pageData[i].nombreAcreditado} ${res.data.pageData[i].apePatAcreditado ? res.data.pageData[i].apePatAcreditado : ''} ${res.data.pageData[i].apeMatAcreditado ? res.data.pageData[i].apeMatAcreditado : ''}`;
                res.data.pageData[i].nombreAgenteCompleto = `${res.data.pageData[i].idCobrador} - ${res.data.pageData[i].nombreAgente} ${res.data.pageData[i].apePatAgente ? res.data.pageData[i].apePatAgente : ''} ${res.data.pageData[i].apeMatAgente ? res.data.pageData[i].apeMatAgente : ''}`;
                res.data.pageData[i].fecha = this.momentVar(new Date(res.data.pageData[i].fecha)).format('DD/MM/YYYY HH:mm');
            }
            this.telefonos = res.data.pageData;
            this.contabilizadorDeFiltrosTotalesTelefonos();
            this.loadings.telefonos = false;
        }, (error: any) => {
            this.app.showError(error);

            this.loadings.telefonos = false;
        });
    }


    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.idLender = lender.idFinanciera;
                    this.filters.financiera = lender;
                }
            });
        }
        else {
            this.filters.financiera = null;
        }

    }

    resetFinancieraFilter() {
        this.idLender = null;
        this.filters.financiera = null;
    }

    public clearFilterCorreos(): void {
        Object.keys(this.filters).forEach(key => {
            if ( key != 'idCliente' && key != 'financiera') {
                this.filters[key] = null;
            }
        });
        this.filtrosService.removerFiltros();
        this.filters.page = 1;
        this.filters.filter = false;
        this.idLender = null;
        this.contabilizadorDeFiltrosTotalesCorres();

    }

    public clearFilterTelefonos(): void {
        Object.keys(this.filters).forEach(key => {
            if ( key != 'idCliente' && key != 'financiera') {
                this.filters[key] = null;
            }
        });
        this.filtrosService.removerFiltros();
        this.filters.pageTelefonos = 1;
        this.filters.filter = false;
        this.idLender = null;
    }

    public setFilterCorreos(deactivateHijos: boolean = false): void {
        setTimeout(() => {
            if(deactivateHijos){
              this.hijosActivos = false;
            }
          }, 500);
        const obligatorios = ['fechaFin', 'fechaInicio'];
        const camposNulos = obligatorios.filter(campo => this.filters[campo] === null);

        if (!this.idLender) {
            this.app.showSnackbar('Aviso', 'Es necesario seleccionar un cliente.', 3000, 'warning');
        } else if (camposNulos.length >=  1) {
            this.app.showSnackbar('¡Aviso!', 'Es requerido seleccionar un rango de fechas.', 3000, 'warning');
            this.filtrosSideNavCorreos.toggle();
            return;
        }
        else {

            this.filters.page = 1;
            this.filters.filter = true;
            this.filtrosService.guardarFiltros(this.filters);
            this.obtenerCorreosElectronicos(1);
        }
        this.contabilizadorDeFiltrosTotalesCorres();
    }

    public setFilterTelefonos(deactivateHijos: boolean = false): void {
        setTimeout(() => {
            if(deactivateHijos){
              this.hijosActivos = false;
            }
          }, 500);
            const obligatoriosTelefonos = ['fechaFinTelefonos', 'fechaInicioTelefonos'];
            const camposNulosTelefonos = obligatoriosTelefonos.filter(campo => this.filters[campo] === null);

        if (!this.idLender) {
            this.app.showSnackbar('Aviso', 'Es necesario seleccionar un cliente.', 3000, 'warning');
        }else if(camposNulosTelefonos.length >=  1){
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un rango de fechas.`, 3000, 'warning');
            this.filtrosSideNavTelefonos.toggle();
            return;
        }
         else {
            this.filters.pageTelefonos = 1
            this.filters.filter = true;
            this.filtrosService.guardarFiltros(this.filters);
            this.obtenerTelefonos(1);
        }
        this.contabilizadorDeFiltrosTotalesTelefonos();
    }

    public desactivarHijo(){
        this.filtrosService.asignarOpcion();
        if(this.filters && this.filters.financiera){
            this.filtrosService.guardarFiltros(this.filters, true);
        }
        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.suscribeParams.bind(this));
    }

    public descargarListado(correoTelefono: number): void {
        this.loadingService.setLoading(true);

        let opcion = ''
        let queryParams = ''

        if (correoTelefono === 1 || correoTelefono === 2) {
            opcion = 'Correos'
            if (this.pagination.totalRows > 40000) {
                this.loadingService.setLoading(false);
                return this.app.showSnackbar('Aviso', 'Por favor reduce tu búsqueda a menos de 40000 registros.', 3000, 'warning');
            }
            let queryParamsCorreos = this.queryParamsGlobalCorreo;
            queryParamsCorreos +=  `&downloadType=${correoTelefono == 1 ? 'xls' : 'csv'}`;
            queryParams = queryParamsCorreos;

        } else {
            opcion = 'Telefonos'
            if (this.paginationTelefonos.totalRows > 40000) {
                this.loadingService.setLoading(false);
                return this.app.showSnackbar('Aviso', 'Por favor reduce tu búsqueda a menos de 40000 registros.', 3000, 'warning');
            }
            let queryParamsTelefonos = this.queryParamsGlobal;
            queryParamsTelefonos +=  `&downloadType=${correoTelefono == 3 ? 'xls' : 'csv'}`;
            queryParams = queryParamsTelefonos;
        }

        this.visitService.descargarInfoRecopilada(queryParams).subscribe(event => {
            if (event.type === HttpEventType.Response) {
                this.loadingService.setLoading(false);
                const blob = new Blob([event.body], { type: event.body.type });
                const fechaActual = moment().format('YYYYMMDD'); // Formato de fecha, ajustable según necesidad
                saveAs(blob, `Informacion-recopilada-${opcion}-${fechaActual}`);
            }
        }, _error => {
            this.loadingService.setLoading(false);
            this.app.showSnackbar(
                "Aviso",
                "Ocurrió un problema al intentar descargar el archivo.",
                3000,
                "error"
            );
            console.log(_error);
        });
    }


    obtenerQueryParamCorreos(pagina: number) {
        let start = 0;
        this.queryParamsGlobalCorreo = "";
        this.queryParamsGlobalCorreo = `?paginate=true&page=${pagina}&rows=${this.datosConsultaCorreos.rows}&slt=${this.datosConsultaCorreos.slt}&orderBy=${this.datosConsultaCorreos.sortField}${this.datosConsultaCorreos.sortAsc ? ' ' + this.datosConsultaCorreos.sortAsc : ' DESC'}`;
        if (this.filters.financiera)
            this.queryParamsGlobalCorreo += `&idFinanciera=${this.filters.financiera.idFinanciera}`;
        if (this.filters.idAcreditado)
            this.queryParamsGlobalCorreo += `&idExterno=${this.filters.idAcreditado}`;
        if (this.filters.fechaInicio)
            this.queryParamsGlobalCorreo += `&fromDate=${this.filters.fechaInicio.toISOString().substr(0, 10)}`;
        if (this.filters.fechaFin)
             this.queryParamsGlobalCorreo += `&toDate=${this.filters.fechaFin.toISOString().substr(0, 10)}`;

        return this.queryParamsGlobalCorreo;

    }

    obtenerQueryParamTelefonos(pagina: number) {
        let start = 0;
        this.queryParamsGlobal = "";
        this.queryParamsGlobal = `?paginate=true&page=${pagina}&rows=${this.datosConsultaTelefonos.rows}&slt=${this.datosConsultaTelefonos.slt}&orderBy=${this.datosConsultaTelefonos.sortField}${this.datosConsultaTelefonos.sortAsc ? ' ' + this.datosConsultaTelefonos.sortAsc : ' DESC'}`;
        if (this.filters.financiera)
            this.queryParamsGlobal += `&idFinanciera=${this.filters.financiera.idFinanciera}`;
        if (this.filters.idAcreditadoTelefonos)
            this.queryParamsGlobal += `&idExterno=${this.filters.idAcreditadoTelefonos}`;
        if (this.filters.fechaInicioTelefonos)
            this.queryParamsGlobal += `&fromDate=${this.filters.fechaInicioTelefonos.toISOString().substr(0, 10)}`;
        if (this.filters.fechaFinTelefonos)
            this.queryParamsGlobal += `&toDate=${this.filters.fechaFinTelefonos.toISOString().substr(0, 10)}`;

        return this.queryParamsGlobal;

    }

    setSortFieldCorreos(sort: any){
        this.datosConsultaCorreos.sortAsc = sort.direction;
        if (sort.active !== this.datosConsultaCorreos.sortField) {
            this.datosConsultaCorreos.sortField = sort.active;
          }
          this.obtenerCorreosElectronicos(1);
    }

    setSortFieldTelefonos(sort: any){
        this.datosConsultaTelefonos.sortAsc = sort.direction;
        if (sort.active !== this.datosConsultaTelefonos.sortField) {
            this.datosConsultaTelefonos.sortField = sort.active;
        }
        this.obtenerTelefonos(1);
    }

    onPage(newPagina: any): void {
        if(newPagina.pageIndex+1 !==  this.pagination.page || newPagina.pageSize !== this.datosConsultaCorreos.rows){
            this.datosConsultaCorreos.rows = newPagina.pageSize;
            this.obtenerCorreosElectronicos(newPagina.pageIndex+1);
        }
    }

    onPageTelefono(newPagina: any): void {
        if(newPagina.pageIndex+1 !==  this.pagination.page || newPagina.pageSize !== this.datosConsultaTelefonos.rows){
            this.datosConsultaTelefonos.rows = newPagina.pageSize;
            this.obtenerTelefonos(newPagina.pageIndex+1);
        }
    }

    onClick({ row, columnPressed }) {

        if(columnPressed === 'idExternoCliente') {
             this.linkDetalleCuenta(row);
         }else if(columnPressed === 'nombreAgenteCompleto'){
             this. linkDetalleAgente(row);
         }
     }

     linkDetalleCuenta(cuenta: any) {
         let queryString = `?idExterno=${cuenta.idExternoCliente}`;
         queryString += cuenta.subIdExterno != null ? `&subIdExterno=${cuenta.subIdExterno}` : "";

         if(this.sessionData.isSuperAdmin){
             queryString += "&idCliente="  + cuenta.idCliente;
         };
         this.routeNavigate.navigateByUrl('/informacion-recopilada/cuenta' + queryString);

     }

     onCtrlClick({ row, columnPressed }) {
        if(columnPressed === 'idExternoCliente') {
            this.ctrlClickLinkDetalleCuenta(row);
        }
    }

    ctrlClickLinkDetalleCuenta(cuenta: any) {
        let queryString = `?idExterno=${cuenta.idExternoCliente}`;
         queryString += cuenta.subIdExterno != null ? `&subIdExterno=${cuenta.subIdExterno}` : "";

         if(this.sessionData.isSuperAdmin){
             queryString += "&idCliente="  + cuenta.idCliente;
         };

        window.open('/cuentas/domicilios/account' + queryString, '_blank');

    }

     linkDetalleAgente(data: any) {
         this.routeNavigate.navigateByUrl('/informacion-recopilada/agentes/' + data.idCobrador);
     }
}

export interface ICorreosElectronicos {
    idExternoCliente: number
    nombreAcreditado: string;
    nombreAgente: string
    correo: string;
    fecha: Date;
    apePatAcreditado: string,
    apeMatAcreditado: string,
    apePatAgente: string,
    apeMatAgente: boolean;
}
export interface ITelefonos {
    idExternoCliente: number
    nombreAcreditado: string;
    nombreAgente: string
    numeroTelefono: string;
    fecha: Date;
    apePatAcreditado: string,
    apeMatAcreditado: string,
    apePatAgente: string,
    apeMatAgente: boolean;
}
export interface IPaginatorCorreosElectronicos {
    page: number;
    pageData: [ICorreosElectronicos];
    pages: number;
    totalRows: number;
}
export interface IPaginatorTelefonos {
    page: number;
    pageData: [ITelefonos];
    pages: number;
    totalRows: number;
}
