import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { ApplicationService } from '../../application/shared/application.service';
import { OperationTag } from "../../operation-tag/operation-tag.model";
import { OperationTagService } from "../../operation-tag/operation-tag.service";
import { Page } from '../../shared/interfaces/page';
import { ImportService } from '../import.service';
import { SessionData } from '../../shared/interfaces/session-data';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import {ModalEstatusCuentaComponent} from '../../account/modales/estatus/estatus-cuenta.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { VisitService } from 'src/app/visit/visit.service';
import { Utils } from 'src/app/shared/components/utils'; 

declare let $: any;
@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css'],
  providers: [
    ImportService,
    OperationTagService,
    VisitService
  ]
})
export class AccountsComponent implements OnInit {
  private $statusModal: any;
  public tags: OperationTag[];
  sessionData: SessionData;
  allAccountsSelected: boolean = false;
  accounts: any;
  selectedAccounts: Array<any>
	displayedColumns: string[] = [
    'select',
    'id',
		'error',
		'resultado',
		'estatus',
		'probabilidadVisita'
  ];

  visitProbability: any = {
    'probabilityVeryHigh': 4,
    'probabilityHigh': 3,
    'probabilityLow': 5,
    'probabilityVeryLow': 2,
    'probabilityWithoutCoverage': 1
  }

  dataSource = null;
  dataSourceTotal: number = 0;
  selectedTag = null;
  selectedStatus = 'Inactiva';
  page: Page;
  params = new URLSearchParams();
  subscription: Subscription;
  title: string = 'Cuentas';
  erroresGenerales: Array<any>;
  public idTipoImportacion: number;
  public motivos: any;

  @ViewChild(MatPaginatorModule) paginator: MatPaginatorModule;

  constructor(
    private visitService: VisitService ,
    private importService: ImportService,
    private appService: ApplicationService,
    public dialogRef: MatDialogRef<AccountsComponent>,
    public dialog: MatDialog,
    private rootElement: ElementRef,
    private accountTagService: OperationTagService,
    private localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.allAccountsSelected = false;
    this.selectedAccounts = [];
   }

  ngOnInit() {
    this.sessionData = this.localStorageService.getSessionData();
    this.idTipoImportacion = this.data.idTipoImportacion;
    this.erroresGenerales = this.data.erroresGenerales ? this.data.erroresGenerales : [];

    if( this.data.filter == 'errors' ) {
      this.title = 'Errores de importación';
      this.displayedColumns = ['select', 'id','subId', 'resultado'];
    } else if( this.data.filter == 'cuentas-archivo' ) {
      this.title = 'Detalle Cuentas/Errores de importación';
      this.displayedColumns = ['select', 'id', 'subId','folioDomicilio', 'domicilio', 'estatus', 'error', 'resultado', 'probabilidadVisita'];
    } else if( this.data.filter == 'imported' ) {
      this.title = 'Cuentas efectiviamente procesadas';
      this.displayedColumns = ['select', 'id', 'subId','folioDomicilio', 'domicilio', 'estatus', 'probabilidadVisita'];
    } else if( this.data.filter == 'geo-exact' ) {
      this.title = 'Cuentas con geolocalización exacta';
      this.displayedColumns = ['select', 'id', 'subId','estatus', 'probabilidadVisita'];
    } else if( this.data.filter == 'geo-inexact' ) {
      this.title = 'Cuentas con geolocalización inexacta';
      this.displayedColumns = ['select', 'id', 'subId','estatus', 'probabilidadVisita'];
    } else if( this.data.filter == 'geo-no' ) {
      this.title = 'Cuentas sin geolocalización';
      this.displayedColumns = ['select', 'id', 'subId','estatus', 'probabilidadVisita'];
    }
    // const slt = Object.assign([], this.displayedColumns);
    // slt.shift();

  	this.params.set('idImportacion', this.data.id);
    //this.params.set('paginate', '1');
    //this.params.set('orderBy', 'idExterno DESC');
    //this.params.set('page', '1');
    //this.params.set('rows', '5');
    //this.params.set('slt', slt.join(','));
    this.$statusModal = $(this.rootElement.nativeElement).find('.ui.status.modal');

    this.loadAccounts();
    this.loadOperationTags();
    this.loadMotivos();
  }

  showStatusModal(): void {
    this.$statusModal.modal('show');
  }

  selectAllAccounts(): void {
    this.selectedAccounts = [];
    
    for( let i = 0; i < this.accounts.length; i++ ) {
      if( !this.accounts[i].disabled ) {
        this.accounts[i].selected = this.allAccountsSelected;
        let id = this.accounts[i].id;

        if( this.allAccountsSelected && id ) {
          this.selectAccount( id, this.accounts[i].idCliente, this.accounts[i].idAcreditadoPG, this.accounts[i].idCuentaPG, this.accounts[i].idDomicilioAcreditado, this.accounts[i].idCuenta );
        }
      }
    }
  }

  selectAccount(id: string, idCliente: number, idAcreditadoPG: number, idCuentaPG: number, idDomicilioAcreditado: number, idCuenta: number) {

    var newArray = this.selectedAccounts.filter((item) => item.idDomicilioAcreditado === idDomicilioAcreditado);
    //var newArray = this.dataSource.filter((item) => item.selected == true);
    //this.selectedAccounts = newArray;
    //console.log(this.dataSource); 
    if(newArray.length > 0) {
      var array = this.selectedAccounts.filter((item) => item.idDomicilioAcreditado != idDomicilioAcreditado);
      this.selectedAccounts =  array;
    }else {

      this.selectedAccounts.push({
        id: id,
        idCuenta: idCuenta,
        idCliente: +idCliente,
        idAcreditadoPG: +idAcreditadoPG,
        idCuentaPG: +idCuentaPG,
        idFinanciera: this.sessionData.idFinanciera,
        idDomicilioAcreditado: idDomicilioAcreditado,
        domicilios: [{idDomicilioAcreditado:idDomicilioAcreditado}]
      });      
        
    }
    
    /*
    let idx = this.selectedAccounts.indexOf(id);
    if( idx > -1 ) {
      this.selectedAccounts.splice(idx, 1);
    } else {
      if( id ) {
        //this.selectedAccounts.push(id);
        //alex
        
        this.selectedAccounts.push({
          id: id,
          idCuenta: idCuenta,
          idCliente: +idCliente,
          idAcreditadoPG: +idAcreditadoPG,
          idCuentaPG: +idCuentaPG,
          idFinanciera: this.sessionData.idFinanciera,
          domicilios: [{idDomicilioAcreditado:idDomicilioAcreditado}]
        });

      }
    }*/
  }

  limpiarAgente(objecto: any){
    var newArray = this.selectedAccounts.filter((item) => item.idCobrador !== objecto.idCobrador && item.idProducto !== objecto.idProducto);
    this.selectedAccounts =  newArray;
  }

  loadOperationTags(): void {
    let etiquetas: OperationTag[] = <OperationTag[]>JSON.parse(localStorage.getItem('etiquetasOperaciones'));
    if (etiquetas) {
      this.tags = etiquetas;
    } else {
      this.accountTagService.getAll().subscribe(
        res => {
          this.tags = res;
          localStorage.setItem('etiquetasOperaciones', JSON.stringify(res));
        },
      );
    }
  }

  setStatus(): void {
    const loading = this.appService.showLoading('Cambiando estatus de cuenta(s)...');
    this.importService.changeEstatusAccount(this.data.idCliente, this.data.idFinanciera, this.selectedStatus, this.selectedTag, this.selectedAccounts).subscribe(
      res => {
        this.selectedAccounts = [];
        this.selectedTag = null;
        this.selectedStatus = 'Inactiva';

        this.closeModals();
        this.appService.showSnackbar('¡Aviso!', 'Se cambia estatus de cuenta(s) correctamente.', 3000, 'success');
        this.loadAccounts();
        this.appService.hideLoading(loading);
      }, err => {
        this.appService.hideLoading(loading);
        this.appService.showError(err);
      });
  }

  closeModals(): void {
    this.$statusModal.modal('hide');
  }

  loadAccounts() {
    const loading = this.appService.showLoading('Cargando cuentas...');

    this.buildParams(this.params);

    if( this.data.filter == 'errors' ) {
      this.importService.getErrors(this.data.idCliente, this.data.id, this.params).pipe(take(1)).subscribe(
        (data: Array<any>) => {
          let dataSource: any = [];
          for( var x=0; x<data.length; x++ ) {
            let currentAccountErrors = JSON.parse( data[x].errores );
            let queryString = '';

            if(data[x].subidexterno){
              queryString = "&subIdExterno="  + encodeURIComponent(data[x].subidexterno);
            };

          if(this.sessionData.isSuperAdmin){
            queryString += "&idCliente="  + encodeURIComponent(data[x].idCliente);
          };
            
            dataSource.push({
              id: data[x].idExterno ? data[x].idExterno : data[x].folioVisita,
              subId: data[x].subIdExterno ? data[x].subIdExterno : '',
              disabled: this.idTipoImportacion == 4 ? true : false,
              idExterno: data[x].idExterno,
              folioVisita: data[x].folioVisita,
              resultado: currentAccountErrors.join(', '),
              selected: false,
              domicilio: this.formarDomicilio(data[x]),
              urlDetalleCuenta: `/cuentas/domicilios/account?idExterno=${data[x].idExterno}${queryString}`
            });
          }
          
          this.allAccountsSelected = false;
          this.selectedAccounts = [];
          this.accounts = dataSource;
          this.dataSourceTotal = dataSource.length;
          this.dataSource = new MatTableDataSource<any>(dataSource);
          this.appService.hideLoading(loading);
        }, err => {
          this.appService.hideLoading(loading);
          this.appService.showError(err);
        });
    } else {
      this.importService.getAccounts(this.data.idCliente, this.data.id, this.params).pipe(take(1)).subscribe(
        (data: Array<any>) => {
          /*this.page = {
            page: data.page,
            pages: data.pages,
            totalRows: data.totalRows,
            pageData: null,
            rows: Number(this.params.get('rows'))
          };*/

          this.allAccountsSelected = false;
          this.selectedAccounts = [];

          data.forEach((row, idx) => {
            let queryString = '';
            data[idx].id = data[idx].idExterno ? data[idx].idExterno : data[idx].folioVisita;
            data[idx].subId = data[idx].subIdExterno ? data[idx].subIdExterno : '';
            data[idx].disabled = this.idTipoImportacion == 4 ? true : false;
            data[idx].selected = false;
            data[idx].domicilio = this.formarDomicilio(data[idx]);
            data[idx].estatus = data[idx].estatus === 'Espera' ? 'Visitada': data[idx].estatus

            if(data[idx].subidexterno){
              queryString = "&subIdExterno="  +  encodeURIComponent(data[idx].subidexterno);
            };

            if(this.sessionData.isSuperAdmin){
              queryString += "&idCliente="  +  encodeURIComponent(data[idx].idCliente);
            };

            data[idx].urlDetalleCuenta = `/cuentas/domicilios/account?idExterno=${encodeURIComponent(data[idx].idExterno)}${queryString}`;
          });

          this.accounts = data;
          this.dataSourceTotal = data.length;
          this.dataSource = new MatTableDataSource<any>(data);
          this.appService.hideLoading(loading);
        }, err => {
          this.appService.hideLoading(loading);
          this.appService.showError(err);
        });
    }
  }

  formarDomicilio(dataItem: any): string {
    return Utils.obtenerDomicilioCompleto({
      calle: dataItem.calle,
      numeroExt: dataItem.numeroExt,
      numeroInt: dataItem.numeroInt,
      codigoPostal: dataItem.codigoPostal,
      colonia: dataItem.colonia,
      municipio: dataItem.municipio,
      estado: dataItem.estado,
      domicilioCompleto: dataItem.domicilioCompleto 
    });
  }


  download() {
    const loading = this.appService.showLoading('Generando archivo...');
    const params = this.buildParams(new URLSearchParams());
    this.importService.exportLayout(this.data.idCliente, this.data.id, params).pipe(take(1)).subscribe(
      (blob) => {
        let extension = 'xlsx';

        if (blob.type === 'application/csv') {
          extension = 'csv';
        } else if (blob.type === 'application/vnd.ms-excel') {
          extension = 'xls';
        }

        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = `Kobra_Layout.${extension}`;
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.appService.hideLoading(loading);
      }, err => {
        this.appService.hideLoading(loading);
        this.appService.showError(err);
      });
  }

  buildParams(params: URLSearchParams) {
    params.delete('importadas');
    params.delete('error');
    params.delete('geolocExactas');
    params.delete('geolocInexactas');
    params.delete('noGeolicalizadas');
    params.delete('idTipoCobertura');

    params.set('idFinanciera', this.data.idFinanciera);

    if( this.data.filter === 'errors' ) {
      params.set('error', '1');
    }

    if( this.data.filter === 'imported' ) {
      params.set('importadas', '1');
      params.set('error', '0');
    }

    if( this.data.filter === 'geo-exact' ) {
      params.set('geolocExactas', '1');
      params.set('error', '0');
    } else if( this.data.filter === 'geo-inexact' ) {
      params.set('geolocInexactas', '1');
      params.set('error', '0');
    } else if( this.data.filter === 'geo-no' ) {
      params.set('noGeolicalizadas', '1');
      params.set('error', '0');
    }

    if (this.data.filter === 'probability-very-high') {
      params.set('idTipoCobertura', this.visitProbability.probabilityVeryHigh);
    } else if (this.data.filter === 'probability-high') {
      params.set('idTipoCobertura', this.visitProbability.probabilityHigh);
    } else if (this.data.filter === 'probability-low') {
      params.set('idTipoCobertura', this.visitProbability.probabilityLow);
    } else if (this.data.filter === 'probability-very-low') {
      params.set('idTipoCobertura', this.visitProbability.probabilityVeryLow);
    } else if (this.data.filter === 'probability-without-coverage') {
      params.set('idTipoCobertura', this.visitProbability.probabilityWithoutCoverage);
    }

    return params;
  }

  onChangePage(pageEvent) {
    if (pageEvent.previousPage !== pageEvent.pageIndex) {
      this.params.set('page', pageEvent.pageIndex + 1);
    }

    this.params.set('rows', pageEvent.pageSize);

    this.loadAccounts();
  }

  onClose() {
    this.dialogRef.close(false);
  }

  private loadMotivos(): void {
    // this.loader = true;
    this.visitService.obtenerEtiquetasOperaciones()
        .subscribe(
            motivos => {
                this.motivos = motivos;
                //this.loader = false;
            },
            error => this.appService.showError(error)

        );
  }

  openDialogEstatus(): void {

    if(this.selectedAccounts.length > 0){
      const dialogRef = this.dialog.open(ModalEstatusCuentaComponent, {
        //height: '400px',
        width: '500px',
        //disableClose: true,
        data:{  
                opcion: 3,
                accounts: this.accounts,      
                motivos: this.motivos,
                domiciliosSeleccionados: [],
                domicilios: [],
                selectedAccount: this.selectedAccounts,
                selectedTag: this.selectedTag
            }
      });

      dialogRef.afterClosed().subscribe(result => {

          if(result) {
              this.loadAccounts();
              
          }   
      });
      }else{
        this.appService.showSnackbar(
          "Aviso",
          "Favor de seleccionar por lo menos una cuenta",
          3000,
          "warning"
        );
      }
    
  }

  onLinkDetalleCuenta(cuenta: any) {
    window.open(cuenta.urlDetalleCuenta, '_blank');
  }
}
