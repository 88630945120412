import { ServicesGestoresService } from '@servicesGestores/services-gestores.service';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { StorageKobraService } from './../core/http/storage-kobra/storage-kobra.service';
import { ServiciosBiService } from './../core/http/servicios-bi/servicios-bi.service';
import { SessionData } from './../shared/interfaces/session-data';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../authentication/auth.service';
import { Router, Event as RouterEvent, ActivationEnd, NavigationEnd } from "@angular/router";
import { LocalStorageService } from './../shared/services/local-storage.service';
import { Component, OnInit, ViewChild, ElementRef,  } from '@angular/core';
import { MatToolbar } from '@angular/material/toolbar';
import { MatSidenav } from '@angular/material/sidenav';
import { Menu } from './modelos/menu.model';
import { LayoutService } from './servicios/layout.service';
import { User } from "../user/user.model";
import { UserService } from "../user/user.service";
import { ApplicationService } from '../application/shared/application.service';
import { currentEnvironment } from '../../environments/current/current-environment';
import { environmentSelector } from '../../environments/environment.selector';
import { PermisosService } from 'src/app/application/shared/permisos.service';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { take } from 'rxjs/internal/operators/take';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';
import { ClientService } from '../core/http/client/client.service';
import { MobileKobraService } from '../core/http/mobile-kobra/mobile-kobra.service';
import { ServicesKobraService } from '../core/http/services-kobra/services-kobra.service';
import { ServiciosSaldosKobraService } from '../core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { CuentaService } from '../cuentas/shared/cuenta.service';
import { NotificationService } from '../dashboard-main/notification.service';

import { Subscription, debounceTime, filter, fromEvent, map, tap } from 'rxjs';
//import { ConsoleReporter } from 'jasmine';
//import { SidenavService } from './servicios/sidenav.service';
import {NotificationInterface} from '../dashboard-main/notification-interface'
import {NotificationTypes} from '../dashboard-main/notification-types'
import { Title } from '@angular/platform-browser';
import { FiltrosService } from '../application/shared/filtros.service';
import { TokenExpirationService } from '../authentication/token-expiration.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  providers: [
    MatToolbar,
    LayoutService,
    UserService,
    ServiciosKobraService,
    CuentaService
  ],

})
export class LayoutComponent implements OnInit {
  private environment = environmentSelector();

  @ViewChild(MatToolbar) toolbarMenu!: MatToolbar;
  @ViewChild('sidenav') sideNav!: MatSidenav;
  @ViewChild('menuList') menuListRef: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  //@ViewChild('sidenavRight', {static: false}) sidenavRight!: MatSidenav;
  sessionData: SessionData;
  mensajeCargando: string = 'Cargando...';
  mostrarCargando: boolean = false;
  sideAbierto: boolean = true;
  routerActual: any;
  menuItems: Menu[] = [];
  loading: boolean = false;
  loadingMenu: boolean = true;
  user: User;
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  ocultarFooterLogo: boolean = true;
  showChildrenComponent = false;
  logo: String = 'assets/img/logo.png';
  hasNewNotifications: boolean = true;
  notifications: NotificationInterface[] = [];
  dropdownOpen: boolean = false;
  unreadNotifications: number = 0;
  notificationTypes = NotificationTypes;

  isDropdownOpen: boolean = false;


  logoDefault: String = 'assets/img/logo.png';
  imagenRota: boolean = false;
  dashAdminText: String;
  dashboardLink: String;
  paises = currentEnvironment.paises;
  paisActual = localStorage.getItem('paisSeleccionado') ? JSON.parse(localStorage.getItem('paisSeleccionado')) : currentEnvironment.paises[0];
  tamanioGrande: boolean = true;
  filtrosServicios;
  private esPrimero: boolean = true;
  private urlRoute: String = "";
  private mostrarLoading: Function;
  private ocultarLoading: Function;
  private subscription: Subscription;

  constructor(
    private rootElement: ElementRef,
    private router: Router,
    private localStorageService: LocalStorageService,
    private layoutService: LayoutService,
    private permisosService: PermisosService,
    private authService: AuthService,
    private app: ApplicationService,
    private lService: ServiciosKobraService,
    private apiKobraService: ApiKobraService,
    private clientService: ClientService,
    private mobileKobraService: MobileKobraService,
    private servicesKobraService: ServicesKobraService,
    private serviciosBiService: ServiciosBiService,
    private storageKobraService: StorageKobraService,
    private servicioVisitas: ServicesVisitasService,
    private servicesGestoresService: ServicesGestoresService,
    private serviciosSaldosKobraService: ServiciosSaldosKobraService,
    private cuentaService: CuentaService,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    //private sidenavService: SidenavService,
    private notificationService: NotificationService,
    private titleService: Title,
    private filtrosService: FiltrosService,
    private tokenExpirationService: TokenExpirationService,


  ){
    this.filtrosServicios = this.filtrosService;
    this.user = new User();
    this.sessionData = this.localStorageService.getSessionData();
    this.router.events.subscribe((event: RouterEvent) => {
      if(this.esPrimero){
        this.esPrimero=false;
        this.urlRoute="";
      }
      if(event instanceof ActivationEnd && event.snapshot.routeConfig.path != ''){
        if(this.urlRoute){
          this.urlRoute=`${event.snapshot.routeConfig.path}/${this.urlRoute}`;
        }
        else{
          this.urlRoute=`${event.snapshot.routeConfig.path}`;
        }
      }

      if(event instanceof NavigationEnd && this.urlRoute != '' && !this.loadingMenu){
        this.esPrimero = true;
        this.validarMenu(`/${this.urlRoute}`);
      }
      else if(event instanceof NavigationEnd && this.urlRoute != ''){
        this.esPrimero = true;
      }
    });
    this.mostrarLoading = this.mostrarLoadingLocal.bind(this);
    this.ocultarLoading = this.ocultarLoadingLocal.bind(this);
  }

  private mostrarLoadingLocal(message: string){
    setTimeout(() => {
      this.mensajeCargando = message;
      this.mostrarCargando = true;
    }, 0);

  }

  private ocultarLoadingLocal(){
    setTimeout(() => {
      this.mostrarCargando = false;
    }, 0);
  }

  ngOnInit(){
    this.loadUser();
    this.app.setFunciones(this.mostrarLoading, this.ocultarLoading);
    this.subscription = this.notificationService.notifications$.subscribe(
      (notifications) => {
          this.notifications = notifications;
          this.updateUnreadNotificationsCount();
      }
    );

  }



  ngOnDestroy() {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
  }

  downloadFile(notificacion: any, event: Event): void {

    event.stopPropagation(); // Esto previene que el evento clic se propague al botón padre
    let environment = environmentSelector();

    const message = notificacion.mensaje;
    const fileNameMatch = message.match(/"([^"]+)"/);
    const fileName = fileNameMatch ? fileNameMatch[1] : 'default_filename.xlsx';

    const idDescarga = notificacion.extraData?.idDescarga;

    let ambiente = environmentSelector();
    let country = "&Country=";
    country+= ambiente.country;
    let queryParams = '?token=' + localStorage.getItem('access-token')+country;


    if (!idDescarga) {
      console.error('Error: idDescarga no está definido en extraData.');
      return;
  }

    fetch(environment.kobraServices.storage + `/descargas/${idDescarga}${queryParams}`)
        .then(response => response.blob())
        .then(blob => {
          // Crear un enlace (a) para descargar el blob
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          this.markAsRead(notificacion);
        })
        .catch(error => console.error('Error al descargar el archivo:', error));
  }

  hasIdDescarga(extraData: any): boolean {
    return extraData && extraData.idDescarga;
  }


  updateUnreadNotificationsCount() {
    this.unreadNotifications = this.notifications.filter(n => !n.banLeida).length;
    if (this.unreadNotifications > 0) {
      this.titleService.setTitle(`Kobra Admin (${this.unreadNotifications})`);
    } else {
      this.titleService.setTitle(`Kobra Admin`);
    }
  }


  markAsRead(notification: any) {
    if(!notification.banLeida){
      this.notificationService.markNotificationAsRead(notification.idNotification);
    }
  }

  markAllNotificationsAsRead(): void {
     this.notificationService.markAllNotificationsAsRead();
  }

  toggleDropdown(): void {
      this.dropdownOpen = !this.dropdownOpen;
  }


  ngAfterViewInit() {

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      if (result.matches) {
        this.sideNav.close();
        this.tamanioGrande = false;
      }
    });
    this.breakpointObserver.observe([
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
        this.tamanioGrande = true;
      }
    });

    fromEvent(this.scrollContainer.nativeElement, 'scroll').pipe(
      debounceTime(100),
      filter(() => {
          const nativeElement = this.scrollContainer.nativeElement;
          return nativeElement.scrollTop + nativeElement.clientHeight >= nativeElement.scrollHeight;
      }),
      tap(() => {
          const currentCount = this.notifications.length;
          this.loadMoreNotifications(currentCount);
      })
  ).subscribe();
  }


  loadMoreNotifications(offset: number) {
    this.notificationService.loadNotifications(offset);
}


  /*toogleSidenav(){
    this.sidenavService.toogleSidenav();
  }*/
  manejarErrorImagen() {
    this.imagenRota = true;
  }

  loadUser(){
    this.loading = true;
    let query = '?expand=admin'
    let userID = localStorage.getItem('userID');
    if (userID) {

      this.userService.getOne(+userID, query).subscribe(
        user => {
          let selectedCountry = localStorage.getItem('paisSeleccionado');
          let country = selectedCountry ? JSON.parse(selectedCountry).abreviacion : null;
          
          if (!country) {
            this.router.navigate(['/auth']);
            return;
          }

          this.logo = user.admin.isSuperAdmin ? this.logo : `${this.environment.kobraServices.storage}/clientes/${user.admin.idFinanciera}/logo?Country=${country}`;
          this.isSuperAdmin = user.admin.isSuperAdmin;
          this.isAdmin = user.admin.isAdmin;
          this.user = user;
          localStorage.setItem('user', JSON.stringify(this.user));
          this.loading = false;
          this.loadMenu(user);

          if( this.isSuperAdmin ) {
            this.dashAdminText = 'Admin';
            this.ocultarFooterLogo = true;
          } else {
            this.dashAdminText = 'Dashboard';
            this.ocultarFooterLogo = false;
          }
        }
      );
    }
  }

  loadMenu(user: User){
    this.loadingMenu = true;
    this.menuItems = [];

    if(this.mostrarCargando){
      this.mostrarCargando = false;
    }
    this.layoutService.getMenu().subscribe((menu: Array<Menu>) => {

      const queryStringToJSON = url => {
        const queryString = url.split('?')[1];

        if (!Boolean(queryString)) {
          return {};
        }

        const pairs = queryString.split('&');
        const result = {};

        pairs.forEach(pair => {
          pair = pair.split('=');
          result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
      };

      this.menuItems = menu.map((item: Menu) => {
        item.banExpandible = false;
        if (item.menuHijos && item.menuHijos.length>0) {
          item.banExpandible = item.menuHijos.some((men) => men.banVisible == true);
          item.menuHijos = item.menuHijos.map((item: Menu) => {
            item.txtQueryParams = queryStringToJSON(item.txtUrl);
            item.txtUrl = item.txtUrl.split('?')[0];

            return item;
          });

          return item;
        }

        item.txtQueryParams = queryStringToJSON(item.txtUrl);
        item.txtUrl = item.txtUrl.split('?')[0];

        return item;
      });
      this.loadingMenu = false;

      if(this.urlRoute){
        this.esPrimero = true;
        this.validarMenu(`/${this.urlRoute}`);
      }

      this.permisosService.guardarPermisos(this.menuItems);
      const currentUri = window.location.pathname;
      for (let index = 0; index < this.menuItems.length; index++) {
        const menu: Menu = this.menuItems[index];
        if(menu.menuHijos && menu.menuHijos.length > 0){
          if(menu.menuHijos.some((menuValidar) => currentUri.includes(menuValidar.txtUrl))){
            menu.banExpandir = true;
          }
        }

      }
      setTimeout(() => {
        const activeLink = document.querySelector('.active-link');
        activeLink?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);

      this.showChildrenComponent = true;
    });

  }

  //Algún dia se unificara la sesion para le cambio de pais por ahora pos no
  cerrarSesion(): void{
    this.lService.post('/logout', {})
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.notificationService.ngOnDestroy();
        this.tokenExpirationService.stopTokenExpirationChecker();
        localStorage.clear();
        localStorage.setItem('paisSeleccionado', JSON.stringify(this.paisActual));
        this.router.navigate(['/auth']);
    });
  }

  validarMenu(url: string){
    if(this.menuItems && this.menuItems.length>0){
      if(!this.obtenerPermisoMenu(this.menuItems, url)){
        this.router.navigate(
          ['/'],
        {
          queryParams: {},
        });
      }
      else{

      }
    }
  }

  obtenerPermisoMenu(menu: Menu[], url: string) : boolean{
    var respuesta = false;

    for (let index = 0; index < menu.length; index++) {
      const item = menu[index];

      if(item.menuHijos && item.menuHijos.length > 0){
        respuesta = this.obtenerPermisoMenu(item.menuHijos, url);
      }

      if(!respuesta && !item.banExpandible && item.txtUrl.trim() == url.trim()){
        respuesta = true;
      }

      if(respuesta){
        index = menu.length;
        return respuesta;
      }
    }
    return respuesta;
  }

  public filtrarPaises() {
    return this.paises.filter(pais => pais.nombre != this.paisActual.nombre);
  }

  /*public cambioPais(pais){
    this.paisActual = pais;
    this.cerrarSesion(true);
  }*/


  //algun dia se podrá unificar la sesión bien por ahora seguira haciendo el merequetengue
  public cambioPais(pais){
    this.authService.reloadBaseUrl(pais.abreviacion);
    this.mostrarCargando = true;
    localStorage.setItem('paisSeleccionado', JSON.stringify(pais));
    this.authService.loginUpdate().subscribe(
      res => {
        localStorage.setItem('userID', res.data.idUsuario.toString());
        localStorage.setItem('access-token', res.data.token);
        this.localStorageService.saveToken(res.data.token);
        this.sessionData = this.localStorageService.getSessionData();
        this.paisActual = pais;
        localStorage.setItem('notifications', JSON.stringify([]));
        this.notificationService.initSocket();

        if(!this.router.url || this.router.url == '/'){
          window.location.reload();
        }
        else{
          this.userService.reloadBaseUrl(this.paisActual.abreviacion);
          this.layoutService.reloadBaseUrl(this.paisActual.abreviacion);
          this.lService.reloadBaseUrl(this.paisActual.abreviacion);
          this.apiKobraService.reloadBaseUrl(this.paisActual.abreviacion);
          this.clientService.reloadBaseUrl(this.paisActual.abreviacion);
          this.mobileKobraService.reloadBaseUrl(this.paisActual.abreviacion);
          this.servicesKobraService.reloadBaseUrl(this.paisActual.abreviacion);
          this.serviciosBiService.reloadBaseUrl(this.paisActual.abreviacion);
          this.storageKobraService.reloadBaseUrl(this.paisActual.abreviacion);
          this.servicioVisitas.reloadBaseUrl(this.paisActual.abreviacion);
          this.servicesGestoresService.reloadBaseUrl(this.paisActual.abreviacion);
          this.serviciosSaldosKobraService.reloadBaseUrl(this.paisActual.abreviacion);
          this.cuentaService.reloadBaseUrl(this.paisActual.abreviacion);
          this.loadUser();
          this.router.navigate([''])/*.then(()=>{
            window.location.reload();
          })*/;
        }
      },
      err => {
        this.snackBar.open('No se pudo realizar el cambio de país', 'OK', {
          duration: 5000,
          panelClass: ['error']

        });
        this.mostrarCargando = false;
        this.authService.reloadBaseUrl(this.paisActual.abreviacion);
        localStorage.setItem('paisSeleccionado', JSON.stringify(this.paisActual));
        this.loading = false;
      }
    );
  }
}
