<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false;"></router-outlet>
<div class="app content kobra">
  <div class="button-container-wrapper button-container">
    <div class="button-toggle-group-container right">
      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
        <mat-button-toggle value="recorrido"
          matTooltip="Recorrido de hoy"
          mat-tooltip-panel-above
          *ngIf="!agent.deleted && !loadingAgent"
          (click)="abrirRecorridoDeHoy()"
          >
          <mat-icon class="kobra-icon">route</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="acciones"
          matTooltip="Acciones"
          mat-tooltip-panel-above
          [matMenuTriggerFor]="menu">
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item
      (click)="changeState('down-general')"
      *ngIf="!agent.deleted && sessionData.isSuperAdmin"
    >
      <mat-icon color="warn" style="margin-right: 16px;">do_not_disturb-on</mat-icon>
      <span style=>Dar de baja</span>
    </button>
    <button mat-menu-item
      *ngIf="agent.deleted && !bajaPorDesasignacion && sessionData.isSuperAdmin"
      (click)="changeState('up-general')"
      >
      <mat-icon class="kobra-icon" style="margin-right: 16px;">task-alt</mat-icon>
      <span>Dar de alta</span>
    </button>
    <button mat-menu-item
      *ngIf="!agent.deleted && !bajaPorDesasignacion && sessionData.isSuperAdmin"
      (click)="changeState('down-punishment')"
    >
      <mat-icon color="warn" style="margin-right: 16px;">do_not_disturb-on</mat-icon>
      <span>Aplicar castigo por desasignación</span>
    </button>
    <button mat-menu-item
      *ngIf="bajaPorDesasignacion && sessionData.isSuperAdmin"
      (click)="changeState('up-punishment')"
    >
      <mat-icon class="kobra-icon" style="margin-right: 16px;">add</mat-icon>
      <span>Quitar castigo por desasignación</span>
    </button>
    <button mat-menu-item
      (click)="openDialogLimiteCuentas()"
    >
      <mat-icon class="kobra-icon" style="margin-right: 16px;">tag</mat-icon>
      <span>Límite de cuenta apartadas</span>
    </button>
  </mat-menu>

  <section class="centrado">
    <mat-card class="card-general">
      <!-- <mat-card-header>
        <mat-card-title>Información personal</mat-card-title>
      </mat-card-header> -->
      <mat-card-actions  align="end" *ngIf="!agent.deleted && !loadingAgent && !editingPersonalInfo">
        <button mat-icon-button
          value="Menu"
          matTooltip="Acciones"
          mat-tooltip-panel-above
          [matMenuTriggerFor]="menuAgente"
        >
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menuAgente="matMenu">
          <button mat-menu-item
            matTooltip="Editar datos del agente"
            (click)="editingPersonalInfo = !editingPersonalInfo">
            <mat-icon class="kobra-icon">edit</mat-icon>Editar
          </button>
      </mat-menu>
      </mat-card-actions>
      <mat-card-content>
        <app-loader *ngIf="loadingAgent" [message]="'Obteniendo información del agente'"></app-loader>
          <div *ngIf="!loadingAgent" [ngClass]="{'kobra three columns': !editingPersonalInfo}">
            <div class="kobra column" *ngIf="!editingPersonalInfo">
              <img [src]="getSafeUrl(agent.profilePictureFullPath)" (error)="onErrorImageLoad()" class="img-cv">

              <!--foto-zoom [foto]="agent.profilePictureFullPath" [posicion]="1" style="max-width: 100%;"></foto-zoom-->
            </div>
            <div class="kobra column two-colspan" *ngIf="!editingPersonalInfo">
              <div class="kobra two columns">
                <div class="kobra column">
                  <span class="kobra-titulo"> {{agent?.fullName}}</span><br>
                    <span class="fec-registro"> Agente desde: {{agent?.createdAt | date:'dd/MM/yyyy'}}</span>

                    <div class="sub header danger" *ngIf="agent.deleted && !bajaPorDesasignacion" style="color: red;">
                      Agente dado de baja
                    </div><br>
                    <div class="sub header" *ngIf="agent.deleted && bajaPorDesasignacion" style="color: red;">
                      Agente dado de baja por alto porcentaje de desasignación
                    </div>
                    <!-- <form [formGroup]="formStatus">
                      <mat-form-field style="width: 90%;">
                        <mat-select
                          formControlName="idCobradorEstatus"
                          [attr.disabled] = "!sessionData.isSuperAdmin ? '' : null"
                        >
                          <mat-option [value]="null" *ngIf="cargandoEstatus">
                            Cargando estatus...
                          </mat-option>
                          <mat-option [value]="agentStatus.idCobradorEstatus" *ngFor="let agentStatus of agentsStatus">
                            {{agentStatus.nombre}}
                          </mat-option>
                        </mat-select>
                        <mat-icon>equalizer</mat-icon>
                      </mat-form-field>
                  </form> -->
                </div>

                <div style="text-align: center;">
                  <calificacion-visita-estrellas
                        [calificacion]="agent.avgRate"
                        [disabled]="true">
                    </calificacion-visita-estrellas>
                    <!-- <div class="cell">
                    <img src="/assets/img/certificado.png" style="height: 50px; width: auto;" *ngIf="agent.certificate">
                  </div> -->
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="kobra three columns">
                <div class="kobra column" *ngIf="pais=='MX'">
                  <label for="">NSS</label>
                  {{agent.nss}}
                </div>
                <div class="kobra column texto-responsivo">
                  <label for="">{{labelCurp}}</label>
                  {{agent.curp}}
                </div>
                <div class="kobra column texto-responsivo">
                  <label for="">{{labelRFC}}</label>
                  {{agent.rfc}}
                </div>
              </div>

              <div class="kobra three columns">
                <div class="kobra column">
                  <label for="">Fecha de Nacimiento</label>
                  {{agent.birthdate | date:'dd/MM/yyyy'}}
                </div>
                <div class="kobra column">
                  <label for="">Sexo</label>
                  {{agent.gender == 'M' ? 'Masculino':'Femenino'}}
                </div>
                <div class="kobra column">
                  <label for="">Teléfono Fijo</label>
                  {{agent.phoneNumber}}
                </div>
              </div>

              <div class="kobra three columns">
                <div class="kobra column ">
                  <label for="">Teléfono Celular</label>
                  {{agent.cellphoneNumber}}
                </div>
                <div class="kobra column texto-responsivo">
                  <label for="">Correo</label>
                  {{agent.email}}
                </div>
                <div class="kobra column">
                  <label for="">Buró Laboral</label>
                  {{agent.buroLaboral ? 'Si':'No'}}
                </div>
              </div>

              <div class="kobra three columns">
                <div class="kobra column">
                  <label for="">Estatus</label>
                  {{agent.disponible ? 'Activo':'Inactivo'}}
                </div>
                <div class="kobra column">
                  <label for="">Primer Contacto</label>

                  {{agent.primerContacto ? 'Sí':'No'}}
                </div>
              </div>
            </div>

            <div class="kobra column two-colspan" *ngIf="editingPersonalInfo">
              <agent-form [agent]="agent" [redInterna]="redInterna"
                (onCancel)="editingPersonalInfo = !editingPersonalInfo" (onSave)="savePersonalInformation($event)">
              </agent-form>
            </div>

          </div>
      </mat-card-content>
      <mat-card-actions align="end" class="relleno">
      </mat-card-actions>
    </mat-card>
  </section>


  <section class="centrado" >
    <div class="container">
      <div>
        <div class="valor valor-centered">
          <mat-icon class="ranking-icon ">military_tech</mat-icon>
          {{ranking.globalRank ?? 0 }}
        </div>
        <p class="titulo">
          Ranking Global
        </p>
      </div>

      <div>
        <div class="valor valor-centered">
          <mat-icon class="ranking-icon ">military_tech</mat-icon>
          {{ranking.localRank ?? 0 }}
        </div>
        <div class="titulo">
          Ranking Local
        </div>
      </div>
    </div>

  </section>

  <section class="centrado" *ngIf="agent.interno && camposExtrasAgentes && camposExtrasAgentes.length > 0">
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Información Adicional
      </span>
      <button mat-icon-button value="Menu" matTooltip="Acciones" mat-tooltip-panel-above [matMenuTriggerFor]="menuAccionesEx" class="menu-button" *ngIf="!agent.deleted">
        <mat-icon class="kobra-icon">more_vert</mat-icon>
      </button>
      <mat-menu #menuAccionesEx="matMenu">
        <button mat-menu-item matTooltip="Editar domicilio" mat-tooltip-panel-above (click)="editandoCamposExtras = true;">
          <mat-icon class="kobra-icon">edit</mat-icon>
          Editar información adicional
        </button>

      </mat-menu>
    </div>
    <div class="card-general">
      <mat-card>
        <mat-card-content style="max-height: 500px; overflow: auto;">
          <div class="kobra four columns" *ngIf="!editandoCamposExtras">
            <div class="kobra column" *ngFor="let campoExtra of camposExtrasAgentes; index as e">
              <label for="">{{campoExtra.nombre}}</label>
              {{utils.formatearValoresTipoDato(campoExtra.valor, campoExtra.idTipoDato, campoExtra.nombre)}}
            </div>
          </div>
          <form *ngIf="editandoCamposExtras">
            <app-campos-extras-agentes-form style="max-height: 450px; overflow: auto;" [colsXl]="4" [colsMd]="2" [colsSm]="1" rowHeight="4:1.2" [camposExtrasAgentes]="camposExtrasAgentes"></app-campos-extras-agentes-form>

            <div class="field kobra-forms-buttons" align="right">
              <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancelCamposExtras()">
                <mat-icon class="kobra-icon">
                  close
                </mat-icon> Cancelar</button>
              <button mat-stroked-button class="kobra-button" (click)="guardarCamposextras()">
                <mat-icon>
                  save
                </mat-icon>Guardar</button>
            </div>
          </form>
        </mat-card-content>
        <mat-card-footer *ngIf="isLoadCamposExtras" class="kobra-card-footer">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>

  </section>

  <section class="centrado">
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">Datos Personales</span>
      <mat-card-actions align="end" *ngIf="!agent.deleted">
        <button mat-icon-button value="Menu" matTooltip="Acciones" mat-tooltip-panel-above [matMenuTriggerFor]="menuAcciones" >
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </button>
      </mat-card-actions>

      <mat-menu #menuAcciones="matMenu">
        <button mat-menu-item matTooltip="Editar domicilio" mat-tooltip-panel-above (click)="loadEditAddress(agentAddress)" *ngIf="!loadingAddress">
          <mat-icon class="kobra-icon">edit</mat-icon>
          Editar domicilio
        </button>
        <button mat-menu-item matTooltip="Editar datos bancarios" mat-tooltip-panel-above (click)="showBankForm()" *ngIf="validarMenu('/agentes/editar/cuenta/bancaria')">
          <mat-icon class="kobra-icon">edit</mat-icon>
          Editar datos bancarios
        </button>
      </mat-menu>
    </div>
    <mat-tab-group #tabGroup class="card-general">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon kobra-icon">house</mat-icon>
          <span class="titulo-tab">Domicilio</span>
        </ng-template>
        <mat-card>
          <mat-card-header *ngIf="editAddress">
            <mat-card-title>Editar</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <app-loader *ngIf="loadingAddress" [message]="'Obteniendo domicilio del agente'"></app-loader>
            <div *ngIf="!editAddress && !loadingAddress">
              <div class="kobra column">
                <label for="">Calle</label>
                {{agentAddress.calle || '-'}}
              </div>
              <div class="kobra two columns">
                <div class="kobra column">
                  <label for="">Num. Ext.</label>
                  {{agentAddress.numeroExt || '-'}}
                </div>
                <div class="kobra column">
                  <label for="">Num. Int.</label>
                  {{agentAddress.numeroInt || '-'}}
                </div>
              </div>
              <div class="kobra two columns" *ngIf="pais != 'AR'">
                <div class="kobra column">
                  <label for="">Código Postal</label>
                  {{agentAddress.codigoPostal}}
                </div>
                <div class="kobra column">
                  <label for="">{{labelEstado}}</label>
                  {{agentAddress.estado || '-'}}
                </div>
              </div>
              <div class="kobra two columns" *ngIf="pais != 'AR'">
                <div class="kobra column">
                  <label for="">{{labelMunicipio}}</label>
                  {{agentAddress.municipio || '-'}}
                </div>
                <div class="kobra column">
                  <label for="">{{labelColonia}}</label>
                  {{agentAddress.colonia || '-'}}
                </div>
              </div>
            </div>
            <div *ngIf="editAddress && !loadingAddress">
              <agent-address-form [agentAddress]="agentAddress" [agentID]="agentID" (onCancel)="editAddress = !editAddress" (onSave)="agentAddressSave($event)"></agent-address-form>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon kobra-icon">account_balance_wallet</mat-icon>
          <span class="titulo-tab">Datos Bancarios</span>
        </ng-template>
        <mat-card>
          <mat-card-header *ngIf="editBankInfo">
            <mat-card-title>Editar</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div *ngIf="loadingBank">
              <app-loader [message]="'Cargando datos bancarios del agente...'"></app-loader>
            </div>
            <div *ngIf="!editBankInfo && !loadingBank">
              <div class="kobra three columns">
                <div class="kobra column" *ngIf="pais=='MX'">
                  <label for="">Banco</label>
                  {{agentBank.bank.name}}
                </div>
                <div class="kobra column">
                  <label for="">Cuenta</label>
                  {{agentBank.account}}
                </div>
                <div class="kobra column">
                  <label for="">Clabe</label>
                  {{agentBank.clabe}}
                </div>
              </div>
            </div>
            <div *ngIf="editBankInfo && sessionData.isSuperAdmin">
              <agent-bank-form [agentBank]="agentBank" [agentID]="agentID" (onCancel)="editBankInfo = !editBankInfo" (onSave)="agentBankSave($event)" (saveProceess)="changeSaving($event)" (loadingBancosChange)="changeLoadingBancos($event)"></agent-bank-form>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </section>

  <section class="centrado">
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Bitácora de Movimientos
      </span>
      <mat-card-actions  align="end" >
        <button mat-icon-button
          value="Menu"
          matTooltip="Acciones"
          mat-tooltip-panel-above
          [matMenuTriggerFor]="menuBitacora"
        >
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menuBitacora="matMenu">
          <button mat-menu-item
            matTooltip="Agregar"
            (click)="toggleModoAgregar()">
            <mat-icon class="kobra-icon">add</mat-icon>Nuevo
          </button>
      </mat-menu>
      </mat-card-actions>

    </div>
    <div class="card-general">
      <historial-alta-baja height="500px" *ngIf="!loadingAgent && agent && agent.idAgente" [idAgente]="agent.idAgente"  (modoAgregarChange)="onModoAgregarChange($event)"></historial-alta-baja>
    </div>
  </section>

  <section class="centrado" >
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Experiencia Laboral
      </span>
      <button mat-icon-button value="Menu" matTooltip="Acciones" mat-tooltip-panel-above [matMenuTriggerFor]="menuAccionesEx" class="menu-button" *ngIf="!agent.deleted && sessionData.isSuperAdmin">
        <mat-icon class="kobra-icon">more_vert</mat-icon>
      </button>
      <mat-menu #menuAccionesEx="matMenu">
        <button mat-menu-item matTooltip="Editar domicilio" mat-tooltip-panel-above (click)="loadEditExpLaboral();">
          <mat-icon class="kobra-icon">edit</mat-icon>
          Agregar experiencia laboral
        </button>

      </mat-menu>
    </div>
    <div class="card-general">
      <work-experience-editable-list *ngIf="reloadExperienceList" [agentID]="agentID" [deleted]="agent.deleted"></work-experience-editable-list>
    </div>

  </section>

  <section class="centrado" >
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Referencias
      </span>
      <mat-card-actions align="end" *ngIf="!agent.deleted && sessionData.isSuperAdmin">
        <button mat-icon-button value="Menu" matTooltip="Acciones" mat-tooltip-panel-above [matMenuTriggerFor]="menuReferencia" >
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </button>
      </mat-card-actions>

      <mat-menu #menuReferencia="matMenu">
        <button mat-menu-item (click)="referenciaLaboral()">
          <mat-icon class="kobra-icon">add</mat-icon>
          Nueva referencia laboral
        </button>
        <button mat-menu-item  (click)="referenciaPersonal()">
          <mat-icon class="kobra-icon">add</mat-icon>
          Nueva referencia personal
        </button>
      </mat-menu>
    </div>
    <mat-tab-group  #tabGroupReferencia class="card-general">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon kobra-icon">work</mat-icon>
          <span class="titulo-tab">Laborales</span>
        </ng-template>
        <work-reference-editable-list  [agentID]="agentID" [deleted]="agent.deleted"></work-reference-editable-list>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon kobra-icon">group</mat-icon>
          <span class="titulo-tab">Personales</span>
        </ng-template>
        <personal-reference-editable-list  [agentID]="agentID" [deleted]="agent.deleted"></personal-reference-editable-list>
      </mat-tab>
    </mat-tab-group>
  </section>

  <section class="centrado"  *ngIf="!agent.interno && (agent.idINEFront || agent.idINEBack || agent.idCedulaFiscal)">
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Identificaciones
      </span>
    </div>
    <mat-card class="card-general">
      <mat-card-content style="max-height: 250px;">
        <div class="kobra three columns">
          <div class="kobra column" *ngIf="!agent.interno && agent.idINEFront">
            <h3><b>Identificación Oficial (Frente)</b></h3>
            <foto-zoom [foto]="agent.ineFrontURL" [posicion]="1" [height]="170" (click)="cargarFotoCompleta(agent.ineFrontURL)"></foto-zoom>
          </div>
          <div class="kobra column" *ngIf="!agent.interno && agent.idINEBack">
            <h3><b>Identificación Oficial (Trasera)</b></h3>
            <foto-zoom [foto]="agent.ineBackURL" [posicion]="1" [height]="170" (click)="cargarFotoCompleta(agent.ineBackURL)"></foto-zoom>
          </div>
          <div class="kobra column" *ngIf="!agent.interno && agent.idCedulaFiscal">
            <h3><b>Cédula Fiscal</b></h3>
            <foto-zoom [foto]="agent.cedulaFiscalURL" [posicion]="1" [height]="170" (click)="cargarFotoCompleta(agent.cedulaFiscalURL)"></foto-zoom>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </section>

  <section class="centrado">
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Porcentaje de Vencimiento
      </span>
      <mat-card-actions align="end" >
      <button mat-icon-button value="Menu" matTooltip="Acciones" mat-tooltip-panel-above
      [matMenuTriggerFor]="menuPorcentaje">
      <mat-icon class="kobra-icon">more_vert</mat-icon>
    </button>
    </mat-card-actions>
    <mat-menu #menuPorcentaje="matMenu">
      <button mat-menu-item matTooltip="" (click)="loadPercentageDeAllocation(agentID)">
        <mat-icon class="kobra-icon">filter_alt</mat-icon>Filtrar
      </button>
    </mat-menu>
    </div>
    <mat-card class="card-general">
      <mat-card-content>
        <div *ngIf="!loadingPercentageDeAllocation">
          <div class="kobra three columns">
            <div class="kobra column">
              <mat-date-picker-formater
                class="date-piker"
                [label]="'Fecha Inicio'"
                [placeHolder]="'Capture la fecha inicio'"
                [max]="filterPeriodEndDate ? filterPeriodEndDate : today"
                [(ngModel)]="filterPeriodStartDate"
                [limpiar]="false"
              />
            </div>
            <div class="kobra column">
              <mat-date-picker-formater
                class="date-piker"
                [label]="'Fecha Fin'"
                [placeHolder]="'Capture la fecha fin'"
                [max]="today"
                [min]="filterPeriodStartDate"
                [(ngModel)]="filterPeriodEndDate"
                [limpiar]="false"
              />
            </div>
            <div class="kobra column">
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-content class="centrado-2">
        <div *ngIf="loadingPercentageDeAllocation">
          <app-loader [message]="'Cargando porcentaje de vencimiento...'"></app-loader>
        </div>


        <div class="container">
          <div>
            <div class="valor valor-centered">
              {{percentageDeallocation.totalAssignedAccounts | number }}
            </div>
            <p class="titulo">
              Total Cuentas Asignadas
            </p>
          </div>
          <div class="spinner-section">
            <div class="spinner-container">
              <mat-progress-spinner
                defaultColor = "accent"
                color="warn"
                mode="determinate"
                [value]="percentageDeallocation.percentageDeallocation"
                [strokeWidth]="13"
                diameter="120">
              </mat-progress-spinner>
              <div class="percentage">{{percentageDeallocation.percentageDeallocation}}%</div>
            </div>
            <p class="etiqueta-spiner">Desasignación</p>
          </div>
          <div>
            <div class="valor valor-centered">
              {{percentageDeallocation.totalExpiredAccounts | number }}
            </div>
            <div class="titulo">
              Total Cuentas Vencidas
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-stroked-button
          *ngIf="isActivarBoton"
          style="margin-right: 10px !important;"
          color="secundary"
          (click)="openDialogCuentas(agentID)">
          <mat-icon class="kobra-icon">visibility</mat-icon>Cuentas
        </button>
      </mat-card-actions>
    </mat-card>

  </section>


  <section class="centrado" >
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Clientes Vinculados
      </span>
    </div>
    <div *ngIf="loadingPF">
      <app-loader [message]="'Cargando productos vinculados...'"></app-loader>
    </div>

    <div *ngIf="!loadingPF" class="card-general">
      <div *ngIf="productosPorFinanciera!==null" style="max-height: 336px; overflow: auto;">
        <mat-accordion [multi]="true" >
          <mat-expansion-panel  *ngFor="let datos of productosPorFinanciera; let i = index">
            <mat-expansion-panel-header>
              <mat-panel-title style="font-weight: bold !important;">
                {{ datos.nombreFinanciera }}
              </mat-panel-title>
              <mat-panel-description>
                <!--i-->
                  Fecha vinculación:
                  {{ datos.fechaRegistroCliente | date:'dd/MM/yyyy' }}
                <!--/i-->
              </mat-panel-description>
              <button
                class="boton-desvincular"
                style="margin-right: 15px;"
                mat-icon-button
                (click)="desvincularcionMasiva(datos);$event.stopPropagation();"
                matTooltip="Desvincular cliente"
                mat-tooltip-panel-above
              ><mat-icon style="color: #D93A32;">delete_forever</mat-icon></button>
            </mat-expansion-panel-header>
            <div *ngIf="datos.productos.length > 0">
              <div *ngFor="let productos of datos.productos; let j = index;">
                <hr>
                <div class="kobra three columns">
                  <div class="kobra column">
                    {{productos.nombreProducto}}
                  </div>
                  <div class="kobra column">
                    <i style="color: #38373782;">
                      Fecha vinculación:
                      {{ productos.fechaRegistroProducto | date:'dd/MM/yyyy' }}
                    </i>
                  </div>
                  <div class="kobra column" *ngIf="productos?.idCobradorProducto && sessionData.isSuperAdmin">
                    <button
                      class="boton-desvincular"
                      mat-icon-button
                      (click)="selectedRow = productos; onDelete(i,j)"
                      matTooltip="Desvincular producto"
                      mat-tooltip-panel-above
                    ><mat-icon style="color: #D93A32;">delete_forever</mat-icon></button>
                  </div>
                </div>
                <div *ngIf="productos.idProducto == 15">
                  <div class="kobra three columns" style="padding-left:10px !important;" *ngFor="let credito of creditos">
                    <div *ngIf="productos.idFinanciera == credito.idFinanciera" class="kobra column">
                      {{credito.nombreTipoCredito}}
                    </div>
                    <div class="kobra column">
                      <i style="color: #38373782;">
                        Fecha vinculación:
                        {{ productos.fechaRegistroProducto | date:'dd/MM/yyyy' }}
                      </i>
                    </div>
                    <div *ngIf="(productos.idFinanciera == credito.idFinanciera) && sessionData.isSuperAdmin"
                      class="kobra column">
                      <button
                        class="boton-desvincular"
                        mat-icon-button
                        (click)="selectedCreditos = credito; onDeleteCredito(credito.idCobrador,credito.idAgenteTipoCredito,credito.nombreTipoCredito)"
                        matTooltip="Desvincular productode solicitud"
                        mat-tooltip-panel-above
                      ><mat-icon style="color: #D93A32;">delete_forever</mat-icon></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div *ngIf="productosPorFinanciera == null  && !loadingPF" class="sin-info">
        <i>Sin información para mostrar</i>
      </div>
    </div>
  </section>

  <section class="centrado" *ngIf="ranking.puntos">
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Últimos Puntos Obtenidos
      </span>
    </div>
    <mat-card class="card-general">
      <mat-card-content>
        <app-table [loading]="false" [columns]="tablaRankings.columnas"
        [items]="ranking.indicadores" [headers]="tablaRankings.headers"
        [tableHeaders]="tablaRankings.tableHeaders" [ignoreSortChange]="true"
        [multiSelect]="tablaRankings.multiSelect">
        </app-table>
      </mat-card-content>
    </mat-card>
  </section>

  <section class="centrado">
    <div class="contenedor-encabezado">
      <span class="titulo-encabezado">
        Onboarding Distribuidor
      </span>
    </div>
    <mat-card class="card-general">
      <mat-card-content>
        <app-onboarding-distribuidor-editable [agentID]="agentID" [deleted]="agent.deleted">
        </app-onboarding-distribuidor-editable>
      </mat-card-content>
    </mat-card>
  </section>

</div>
