import { MatAutocompleteTrigger  } from '@angular/material/autocomplete';
import { FormValidation } from 'src/app/shared/form-validation/form-validation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Component, forwardRef, Input, OnChanges, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { debounceTime, map, startWith, filter } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgZone } from '@angular/core';

@Component({
  selector: 'mat-select-search',
  templateUrl: './mat-select-search.component.html',
  styleUrls: ['./mat-select-search.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatSelectSearch),
      multi: true
    }
  ]
})
export class MatSelectSearch implements ControlValueAccessor, OnChanges {
  @Input() hintLabel?: string; 
  @Input() maxlength?: number; 
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() value!: string;
  @Input() labelField!: string;
  @Input() icon: string;
  @Input() options!: any[];
  @Input() disabled: boolean;
  @Input() error: boolean;
  @Input() errorMessage: string;
  @Input() realizarBusqueda?: any;
  @Input() limpiar?: boolean = true;
  @Input() loader?: boolean;
  @Input() minBusqueda?: number = 3;
  @ViewChild('input', { read: ElementRef }) matInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  filteredOptions: Observable<any[]>;
  filteredArray: any[] = null;
  myControl = new FormControl('');
  loading:boolean = false;
  public controlValidator: FormValidation;
  filteredCreated: boolean = false;
  selectedOption: any;
  subscribe: any = null;
  debouncetime: number = 1000;
  private _value: any;
  private onChange: (_: any) => void = () => {};

  constructor(private zone: NgZone){
    this.controlValidator = new FormValidation();
    this.controlValidator.formControl = this.myControl;
    this.controlValidator.message = {
      required: this.errorMessage
    };
    
  }

  onOptionSelected (event: any){
    const selectedOption = event.option.value;
    //this.myControl.setValue(selectedOption, { emitEvent: false });

    if(this.realizarBusqueda){
      this._filterSearch(selectedOption);
    }
    
  }

  ngOnInit() {
    // // Escuchar el evento blur del control
    // this.myControl.valueChanges.pipe(
    //     debounceTime(300), // Esperar un poco para que termine de escribir
    // ).subscribe(() => {
    //     // Cuando ocurre el evento blur, actualizar el valor _value
    //     if (!this.selectedOption) { // Si no hay ninguna opción seleccionada
    //         this._value = this.myControl.value; // Asignar el valor del control a _value
    //         this.onChange(this._value); // Emitir el evento onChange
    //     }
    // });
  }

  ngOnChanges(changes) {
    if(this.disabled){
        this.myControl.disable();
    }
    else{
        this.myControl.enable();
    }

    if(this.realizarBusqueda && !this.filteredCreated){
      this.filteredCreated = true;
      this.myControl.valueChanges.pipe(debounceTime(this.debouncetime)).subscribe((value) => {
        if (value && value.length >= this.minBusqueda) {
          if(((this.value == 'option' && (!this._value || value != this._value[this.labelField])) || (this.value != 'option' && this.value != this._value))){
            this._filterSearch(value);
          }
        }
        else{
          this.filteredArray = null;
          if(this.selectedOption){
            this._value = null;
            this.selectedOption = null;
            this.onChange(this._value);
          }
        }
      });
      return;
    }
    else if(this.realizarBusqueda && this.filteredArray && this.filteredArray.length > 0 && (!this.selectedOption || this._value != (this.value == 'option' ? this.selectedOption : this.selectedOption[this.value]))){
      var option = this.filteredArray.filter(option => option[this.value] == this._value || option == this._value);
      if(option.length == 1){
        this.selectedOption = option[0];
        this.myControl.setValue(option[0][this.labelField]);
        this.filteredArray = null;
      }
      return;
    }
    else if(this.realizarBusqueda){
      //este solamente es solamente para que no intente hacer la funcion de cuando le se envia el array que se utilizara
      return;
    }
    
    if(this.options && this.options.length>0 && !this.filteredCreated){
        this.filteredCreated = true;
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
    }
    if(this.options && this.options.length>0 && (!this.selectedOption || this._value != (this.value == 'option' ? this.selectedOption : this.selectedOption[this.value]))){
        var option = this.options.filter(option => option[this.value] == this._value || option == this._value);
        if(option.length == 1){
            this.selectedOption = option[0];
            this.myControl.setValue(option[0][this.labelField]);
        }
    }
  }

  private _filter(value: string): string[] {
    if(!this.myControl.value && this.selectedOption && this._value == (this.value == 'option' ? this.selectedOption : this.selectedOption[this.value])){
        this._value = null;
        this.selectedOption = null;
        this.onChange(this._value);
    }
    const filterValue = !value && this.selectedOption ? this.selectedOption[this.labelField].toLocaleLowerCase() : value.toLowerCase();
    if(!this.options){
      return null;
    }
    var option = this.options.filter(option => option[this.labelField] == value);
    if(option.length >0){
        this.selectedOption = option[0];
        this._value = this.value == 'option' ? option[0] : option[0][this.value];
        this.onChange(this._value);
    }
    return this.options.filter(option => filterValue ? option[this.labelField].toLowerCase().includes(filterValue) : option);
  }

  private _filterSearch(value: any, seleccionarPrimero: boolean = false){
    var option = this.filteredArray ? this.filteredArray.filter(option => option[this.labelField] == value) : [];
    if(option.length >0){
        this.selectedOption = option[0];
        this._value = this.value == 'option' ? option[0] : option[0][this.value];
        this.onChange(this._value);
        return;
    }

    this.loading = true;
    this.filteredArray = null;

    if(this.subscribe){
      this.subscribe.unsubscribe();
    }

    // Llama a la función recibida
    this.subscribe = this.realizarBusqueda(value).subscribe((data)=>{
      this.filteredArray = data;
      if(seleccionarPrimero){
        this.selectedOption = this.filteredArray[0];
        this.myControl.setValue(this.filteredArray[0][this.labelField]);
        this._value = this.value == 'option' ? this.filteredArray[0] : this.filteredArray[0][this.value];
        this.onChange(this._value);
      }
      this.loading = false;
      this.subscribe.unsubscribe();
    }, (error: any) => {
      this.loading = false;
      this.subscribe.unsubscribe();
    });
  }

  /**Metodos para el ngModel
   * 
   */

  writeValue(value: any): void {
    let buscar: boolean = false;
    if(!this._value){
      buscar = true;
    }
    this._value = value;
    if(!this._value){
      this.selectedOption = null;
      this.myControl.setValue('');
    }
    else{      
      if(this.realizarBusqueda && buscar){
        if(this.value == 'option'){
          this.selectedOption = this._value;
          this.myControl.setValue(this._value[this.labelField]);
        }
        else{
          this._filterSearch(this._value + '', true)
        }
      }
      else{
        if(this.options && this.options.length > 0){
          let option = this.options.filter(option => this.value == 'option' ? option == value : option[this.value] == value);
          if(option.length == 1){
            this.selectedOption = option[0];
            this.myControl.setValue(option[0][this.labelField]);
          }
        }
      }
    }
  }
  
  registerOnChange(onChange: (_: any) => void): void {
    this.onChange = onChange;
  }
  
  registerOnTouched(fn: any): void {}
  
  setDisabledState(isDisabled: boolean): void {}

  public focusInput() {
    if (this.matInput && this.matInput.nativeElement) {
      this.matInput.nativeElement.focus();
    
    }
  }
  
  hasValue(): boolean {
    const inputValue = this.myControl.value;
    return (inputValue !== null && inputValue !== undefined && inputValue.length > 0);
  }
  

  clearValue(): void {
    this._value = null;
    this.selectedOption = null;
    this.myControl.setValue('');
    this.onChange(this._value);
  }

  cerrarAutocomplete() {
    this.autocomplete.closePanel();
  }
}
