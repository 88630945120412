import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ServicesVisitasService } from '../core/http/services-visitas/services-visitas.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService implements CanActivate {

  constructor(
    private router: Router,
    private visitasService: ServicesVisitasService
  ) { }

  canActivate(): boolean {
    var loggedIn: boolean = false;
    if (localStorage.getItem('access-token')) {
      loggedIn = true;
    } else {
      this.router.navigate(['/auth']);
    }
    return loggedIn;
  }

  reloadBaseUrl(archivo:string = ""){
    this.visitasService.reloadBaseUrl(archivo);
  }

  logIn(email, contrasena): Observable<any>{
    let data = {
      email: email,
      contrasena: contrasena,
      idAplicacion: 2,
    };
    
    let response = this.visitasService.post('/login', data, true)
      .pipe(
        map((res) => {
          return res;
        })
      );

    return response;
  }

  verificarCodigo2FA( sessionId: string, codigo2fa: any, email: any): Observable<any>{
    let data = {
      sessionId: sessionId,
      codigo2fa: codigo2fa,
        email: email
    };
    
    let response = this.visitasService.post('/2fa', data, true)
      .pipe(
        map((res) => {
          return res;
        })
      );

    return response;
  }

  loginUpdate(): Observable<any>{
    let data = {
      idAplicacion: 2
    };

    let response = this.visitasService.post('/login/update', data, true)
      .pipe(
        map((res) => {
          return res;
        })
      );

    return response;
  }
}