import { ProductosService } from './../productos.service';
import { Lender } from '@lender/lender.model';
import { LenderService } from './../../../lender/lender.service';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { take, filter } from 'rxjs/operators';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environmentSelector } from '../../../../environments/environment.selector';
import { I } from '@angular/cdk/keycodes';
import {ConfirmacionDialog} from "src/app/modales-genericos/confirmacion/confirmacion-dialog.component";
import { ProductoNvo } from '../productos-nvo.model';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

//import 'jquery-ui-dist/jquery-ui.min.js';
declare var $: any;

@Component({
    selector: 'productos-nvo-list',
    templateUrl: './productos-nvo-list.component.html',
    styleUrls: ['./productos-nvo-list.component.css'],
    providers: [LenderService]
})
export class ProductosNvoListComponent implements OnInit {
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
    private environment = environmentSelector();
    sessionData: SessionData;
    public branches: any[] = [];
    public productos: ProductoNvo[] = [];
    public itemsSelected: any[] = [];
    public pagination: IPaginatorSucursales;
    public filtrosAbiertos: boolean = false;

    public queryParams: URLSearchParams;
    public listFields: any = {};
    public lenders: Lender[] = [];
    public idLender: number = 0;
    private slt: string = '*';
    public ayuda: boolean = false;
    public hijosActivos: boolean = false;
    public contabilizadorFiltros: FiltersContabilizer;
    public totalFiltros: number = 0;
    idCliente: number;

    tabla: any = {
        headers: false,
        multiSelect: false,
        columns: []
    };

    public filters: any = {
        financiera: null,
        nombre: null,
        activa: true,
        excluirDemo: false,
        filtered: false,
        page: 1
    };

    public datosConsulta: any = {
        pageSize: 100,
        rows: 50,
        sortField: 'idProducto',
        sortAsc: true,
        query: "",
        fromRowPage: 0,
        toRowPage: 50
    };

    public loadings: any = {
        conceptosProductos: false,
        borrando: false,
        financieras: true
    };

    constructor(
        private lenderService: LenderService,
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        private productosService: ProductosService,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private dialog: MatDialog,
        private router: Router,
        private rootElement: ElementRef,
        private cdr: ChangeDetectorRef,
    ) {
        this.sessionData = this.localStorageService.getSessionData();
        this.tabla.columns = [
            {
                name: 'ID Cliente',
                key: 'idFinanciera',
                hide: true
            },
            {
                name: 'Cliente',
                key: 'nombreCliente',
                hide: this.sessionData.isSuperAdmin ? false : true
            },
            {
                name: 'ID Producto',
                key: 'idProducto',
                sortName: 'p.id_producto',
                hide: false
            },
            {
                name: 'Nombre',
                key: 'nombre',
                sortName: 'p.nombre',
                hide: false
            },
            {
                name: 'Descripción',
                key: 'descripcion',
                hide: false
            },
            {
                name: 'Tipo Producto',
                key: 'nombreTipoProducto',
                hide: false
            }
        ];
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
    }

    ngOnInit() {
        if (!this.sessionData.isSuperAdmin) {
            this.filters.excluirDemo = false;
        }

        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
    }

    private agregarContabilizadoresDeFiltros(){
   
        this.contabilizadorFiltros.add('Generales', 'generales', [
            'financiera',
            'nombre'
           
        ]);
    
    }
    
    public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);

      }
    public contabilizadorDeFiltrosTotales():void{
        
    
        let totalFiltrosArray: number[] = [];
       
        totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );

    
        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    resultadoFiltros(filtrosNuevos: any) {
        if (filtrosNuevos) {
            this.filters = filtrosNuevos;
            this.filters.filter = true;
            this.filters.filtered = true;
        }

        if(!this.sessionData.isSuperAdmin) {
            this.idLender = this.sessionData.idFinanciera
        }else {
            this.idLender = this.filters.financiera ? this.filters.financiera.idFinanciera : null;
        }
       
        this.loadLenders();
        this.contabilizadorDeFiltrosTotales();
        
    }



    public saveInStorage(index: number, value: boolean): void {
        this.tabla.columns[index].hide = !value;
        localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
    }

    //INICIO EVENTOS TABLA
    onSelected(rows) {
        this.itemsSelected = rows.slice();
    }

    /*onLinkRow({ row, columnPressed }) {
        if (columnPressed === 'nombreSucursal') {
            this.detalleSucursal(row, false);
        }
    }

    onDoubleClick({ row }) {
        this.detalleSucursal(row, false);
    }*/

    sortChange(sort: any) {
       // console.log(sort);
        if (sort.active == this.datosConsulta.sortField) {
            this.datosConsulta.sortAsc = !this.datosConsulta.sortAsc;
        } else {
            this.datosConsulta.sortField = sort.active;
            this.datosConsulta.sortAsc = true;
        }
        this.loadProductos(1);
    }


    public download(): void {
        const token = localStorage.getItem('access-token');
        let country = "&Country=";
        country+= this.environment.country;
        const url = `/sucursales/csv${this.crearURLQuery(1)}&token=${token}${country}`;
        window.open(this.environment.kobraServices.servicios + url);
    }
    //FIN EVENTOS TABLA

    //INICIO Eventos botones

    //Muestra el detalle del producto ademas de validar si será para agregar, editaro o ver info solamente
    agregarProducto() {
        if(this.filtrosAbiertos)
        {
            this.filtrosSideNav.toggle();
            this.filtrosAbiertos = !this.filtrosAbiertos;
        }
        const queryParams: any = {}; // Conservar otros parámetros de consulta

        // Agregar idFinanciera solo si existe idLender
        if (this.idLender) {
            queryParams.idFinanciera = this.idLender;
            queryParams.idCliente = this.filters.financiera.idCliente
        }
        this.router.navigate(
            ['nuevo', {}],
            {
                relativeTo: this.route,
                //queryParamsHandling: 'preserve',
                queryParams: queryParams
            }).then(() => {
                this.itemsSelected = [];
        });
        /*this.router.navigate([`catalogos/sucursales/detalle`], {
          queryParamsHandling: 'preserve'
        });*/
    }

    // Función de edición del producto
    editarProducto() {
        this.router.navigate(['editar'], {
          relativeTo: this.route,
          queryParams: {
            idCliente: this.itemsSelected[0].idCliente,
            idFinanciera: this.itemsSelected[0].idFinanciera,
            idProducto: this.itemsSelected[0].idProducto,
            nombreProducto: this.itemsSelected[0].nombre,
            nombreCliente: this.itemsSelected[0].nombreCliente
          }
        });
    }

    openDialogConfirmacion(producto: ProductoNvo = null): void {
        if(this.filtrosAbiertos)
        {
            this.filtrosSideNav.toggle();
            this.filtrosAbiertos = !this.filtrosAbiertos;
        }
        const dialogRef = this.dialog.open(ConfirmacionDialog, {
            data:{
                    titulo: "Confirmar",
                    mensaje: "¿Deseas eliminar el producto seleccionado?",
                    icono: "delete_forever",
                    colorIcono: "warn",
                    boton1: "No",
                    boton2: "Sí",
                    texto: producto.nombre,
                    claseAccion: "boton-accion-eliminar"
                }
        });
    
        dialogRef.afterClosed().subscribe(result => {
    
            if(result) {
                this.borrarProducto(producto);
            }   
        });
      }

    //Elimina el conceptop producto actual
    borrarProducto(producto: ProductoNvo = null) {
        this.loadings.borrando = true;
        this.app.showLoading("Eliminando registro...");
        this.productosService.eliminarProducto(producto.idCliente, producto.idProducto)
        .pipe(
            take(1)
        ).subscribe((res: any) => {
            this.loadings.borrando = false;
            this.app.hideLoading();
            this.loadProductos(1);
        }, (err: any) => {
            this.loadings.borrando = false;
            this.app.showSnackbar("Error", "Ha ocurrido un error.", 0, "error");
            this.app.hideLoading();
            this.app.showError(err);
        });
    }

    public setFilter(): void {
        this.filters.filtered = true;
        this.filters.page = 1;
        this.filtrosService.guardarFiltros(this.filters);
        this.loadProductos(1);
    }

    public clearFilter(): void {
        Object.keys(this.filters).forEach(key => {
            if (this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera')) {
                this.filters[key] = null;
            }
        });
        this.filters.excluirDemo = true;
        this.filters.activa = true;
        this.filters.filtered = false;
        this.filters.page = 1;
        this.filtrosService.removerFiltros();
        //this.loadProductos(1);
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filters.financiera = lender;
                }
            });
        }
        else {
            this.filters.financiera = null;
        }
      

    }

    //FIN Metodos del modal filtro

    //Metodos Generales
    public filtrar(page: number){
        if(this.idLender){
            this.loadProductos(page);
        }else{
            this.filtrosSideNav.toggle();
            this.app.showSnackbar("Error", "Es necesario seleccionar un cliente.", 0, "warning");
            return;
        }
        this.contabilizadorDeFiltrosTotales();
        this.filtrosService.guardarFiltros(this.filters);
    }

    public limpiarFiltros(){
        this.idLender = null;
        this.filters = {
            financiera: null,
            nombre: null,
            activa: true,
            excluirDemo: false,
            filtered: false,
            page: 1
        }
        //this.filtrosService.guardarFiltros(this.filters);
        //this.loadProductos(1);
        this.filtrosService.removerFiltros();
        this.contabilizadorDeFiltrosTotales();
    }

    public loadProductos(page: number): void {
        this.loadings.conceptosProductos = true;

        let query: string = this.crearURLQuery(page);
        this.productosService.obtenerProductos(this.filters.financiera.idCliente, query)
        .pipe(
            take(1)
        ).subscribe((res: any) => {
            this.loadings.conceptosProductos = false;
            this.pagination = res.data;
            this.productos = ProductoNvo.mapArray(res.data.pageData);
            this.calcFromToRowPage();
            this.cdr.detectChanges();
        }, (err: any) => {
            this.loadings.conceptosProductos = false;
            this.app.showError(err);
        });
        this.contabilizadorDeFiltrosTotales();
    }

    private crearURLQuery(page: number): string {
        let queryParams: string = '';
        let guardarFiltros: boolean = false;
        if (page != this.filters.page) {
            this.filters.page = page;
            guardarFiltros = true;
        }

        queryParams += `?slt=${this.slt}&paginate=true&page=${page}&rows=${this.datosConsulta.rows}`;

        if (this.datosConsulta.sortField) {
            queryParams += '&orderBy=' + this.datosConsulta.sortField + (this.datosConsulta.sortAsc ? ' asc' : ' desc');
        }

        if(this.filters.nombre){
            queryParams += `&nombreProducto=${this.filters.nombre}`;
            this.filters.filtered = true;
            guardarFiltros = true
        }

        if(guardarFiltros){
            this.filtrosService.guardarFiltros(this.filters);
        }

        return queryParams;
    }

    onPage(newPagina: any): void {
        if(newPagina.pageIndex+1 !== this.pagination.page || newPagina.pageSize !== this.datosConsulta.rows){
            this.datosConsulta.rows = newPagina.pageSize;
            this.loadProductos(newPagina.pageIndex+1);
        }
    }

    calcFromToRowPage() {
        if (this.pagination.pageData.length <= 0) {
            this.datosConsulta.fromRowPage = 0;
            this.datosConsulta.toRowPage = 0;
        } else {
            this.datosConsulta.fromRowPage = (this.datosConsulta.rows * this.pagination.page) - (this.datosConsulta.rows - 1);

            if (this.pagination.page === this.pagination.pages) {
                this.datosConsulta.toRowPage = this.pagination.totalRows;
            } else {
                this.datosConsulta.toRowPage = this.pagination.page * this.datosConsulta.rows;
            }
        }
    }

    public loadLenders(): void {
        this.loadings.financieras = true;

        this.lenderService.getAll('active').subscribe(
            res => {
                this.lenders = res;
                this.onChangeLender(this.idLender);
                if(this.productos.length == 0 && !this.sessionData.isSuperAdmin || this.idLender){
                    this.loadProductos(this.filters.page || 1);    
                }

            },
            err => {
                this.app.showError(err);
                this.loadings.financieras = false;
            },
            () => this.loadings.financieras = false
        );
    }

    ayudaComentario(bool: boolean){
        this.ayuda = bool;
    }

    resetFinancieraFilter() {
        this.idLender = 0;
        this.filters.financiera = null;
    }
    //Fin Metodos Generales
    onDoubleClick({ row }) {
        if(this.itemsSelected[0].editable){
            this.editarProducto();
        }else{
            this.app.showSnackbar("¡Aviso!", "Este producto no es editable.", 0, "warning");
        }
    }

    inactivarHijo(){
        this.filtrosService.asignarOpcion();
        if(this.filters && (this.filters.financiera || this.filters.filtered)){
            this.filtrosService.guardarFiltros(this.filters, true);
        }
        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
    }

}

export interface ISucursales {
    idFinanciera: number
    nombreFinanciera: string;
    idSucursal: number
    nombreSucursal: string;
    alias: string;
    descripcion: string,
    createdAt: string,
    updatedAt: string,
    activa: boolean;
    estatus: string;
}

export interface IPaginatorSucursales {
    page: number;
    pageData: [ISucursales];
    pages: number;
    totalRows: number;
}
